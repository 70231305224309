<template>
  <div
    class="bg-image-dog flex justify-center bg-background-extra-light bg-cover bg-bottom bg-no-repeat px-5 md:h-screen md:bg-fixed md:py-8"
  >
    <SeoMetaEditorial :title="title" :description="description" />
    <div
      class="relative z-10 mx-auto my-[8rem] h-fit w-full max-w-[960px] rounded-lg bg-white px-1 py-8 shadow-lg sm:px-4 md:pb-16"
    >
      <div class="flex flex-wrap justify-center">
        <div class="flex w-full justify-center">
          <BrandLogo class="h-[40px] text-primary-default [&_svg]:size-full" />
        </div>
        <h1
          class="mb-2 mt-1 flex min-h-[120px] w-full items-center justify-around bg-[url('~/assets/images/worldmap.svg')] bg-contain bg-center bg-no-repeat text-center"
        >
          <span class="text-xl">Choose your country</span>
        </h1>
      </div>

      <div
        class="mx-auto flex max-w-xl flex-col flex-wrap items-center justify-between md:flex-row md:items-start"
      >
        <NuxtLinkLocale
          v-for="locale in locales"
          :key="locale.code"
          class="m-0 flex w-[calc(100%-40px)] max-w-[260px] items-center whitespace-nowrap border-b bg-white p-4 text-black hover:z-10 hover:shadow-lg md:w-[calc(50%-40px)] md:max-w-full"
          :to="`${$i18n.baseUrl}/${locale.code}`"
          :locale="locale.code"
          @click="setLocaleCookies(locale.code)"
        >
          <Flag :code="locale.code" :name="locale.name" class="mr-2 w-6" />
          {{ locale.name }}
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables';

const i18n = useI18n();
const locales = i18n.locales as Ref<LocaleObject[]>;

const title = 'FirstVet is your online vet';
const description =
  'FirstVet is a digital veterinary clinic offering video meetings with experienced vets directly in your smartphone, tablet or computer. Our experienced vets are available 24/7 to offer help and advice regarding your pet. Get a quick diagnosis or, if needed, we can refer you to the right specialist straight away.';

definePageMeta({
  layout: 'empty',
  middleware: [
    // eslint-disable-next-line consistent-return
    () => {
      const localeCookie = useCookie('locale');
      const firstLocaleCheckCookie = useCookie('first-locale-check');

      // We need to disbale the redirect only for the first time but we need the locale to be fully functional
      // That is why we have to resort on using a "flag" type of cookie to track this initialization
      // while having both the fully functional redirect and first time country setting without taking risks with Nuxt's model
      if (localeCookie.value && firstLocaleCheckCookie.value) {
        return navigateTo(`/${localeCookie.value}`);
      }
    },
  ],
});

defineI18nRoute(false);

const setLocaleCookies = (locale: string) => {
  const maxAge = 60 * 60 * 24 * 365; // 1 year

  setCookie('locale', locale, maxAge);
  setCookie('first-locale-check', 'initialized', maxAge);
};
</script>

//
<style lang="scss" scoped>
.bg-image-dog {
  background-image: url('@brand/assets/images/bg-image.jpg');
  @supports (background-image: url('@brand/assets/images/bg-image.webp')) {
    background-image: url('@brand/assets/images/bg-image.webp');
  }
  background-size: cover;
  background-position: center;
}
</style>
